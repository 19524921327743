import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-axis-values',
  template: `
    <div [class.emphasis]="x > y && x > z">{{ x }}<span>x</span></div>
    <div [class.emphasis]="y > x && y > z">{{ y }}<span>y</span></div>
    <div [class.emphasis]="z > x && z > y">{{ z }}<span>z</span></div>
  `,
  styleUrls: ['./axis-values.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AxisValuesComponent {
  @Input() x: number | string;
  @Input() y: number | string;
  @Input() z: number | string;
}
