import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  NbDialogService,
  NbMediaBreakpointsService,
  NbMenuItem,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { User } from '../../../auth/models/user';
import { AuthQuery, AuthStore } from '../../../auth/state/auth.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TenantsDialogComponent } from '../tenants-dialog/tenants-dialog.component';

@UntilDestroy()
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  userPictureOnly = false;
  user: User;
  tenants = this.authQuery.authorizedTenants();
  tenant$ = this.authQuery.tenant$;

  userMenu: NbMenuItem[] = [
    {
      title: 'LOGOUT',
      icon: 'log-out',
      url: `${environment.connectUrl}/auth/sign-out`,
    },
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authStore: AuthStore,
    private authQuery: AuthQuery,
    private dialogService: NbDialogService,
  ) {}

  ngOnInit(): void {
    this.user = this.authQuery.getUser();

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        untilDestroyed(this),
      )
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl),
      );
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(false, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome(): boolean {
    this.menuService.navigateHome();
    return false;
  }

  tenantChanged(tenant: string): void {
    this.authStore.assumeTenant(tenant);
  }

  openHelp(dialog: TemplateRef<unknown>): void {
    this.dialogService.open(dialog, { autoFocus: false });
  }

  openTenantDialog(): void {
    const ref = this.dialogService.open(TenantsDialogComponent, {
      autoFocus: false,
    });
    ref.onClose.subscribe((result) => {
      result !== undefined && this.authStore.assumeTenant(result);
    });
  }
}
