import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

export type ShockPerTenant = { tenant: string; count: number }[];

@Injectable({
  providedIn: 'root',
})
export class TenantsService {
  private tenants: ShockPerTenant;
  constructor(private http: HttpClient) {}

  async getTenants(): Promise<ShockPerTenant> {
    try {
      if (this.tenants == undefined) {
        this.tenants = await this.http
          .get<ShockPerTenant>(`${environment.snorlaxApi}/shocks/tenants`)
          .toPromise();
      }
      return this.tenants;
    } catch (err) {
      return [];
    }
  }
}
