import { Injectable } from '@angular/core';
import { filterNil, Query, Store, StoreConfig } from '@datorama/akita';
import { User } from '../models/user';
import { TokenProvider } from '../services/token.provider';
import produce from 'immer';
import { switchMap } from 'rxjs/operators';
import {
  StorageService,
  STORAGE_KEYS,
} from '../../@core/utils/storage.service';
export interface AuthState {
  user: User;
  assumedTenant?: string;
  cognitoToken?: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', producerFn: produce })
export class AuthStore extends Store<AuthState> {
  constructor(private storage: StorageService) {
    super({
      ...(storage.get<{ assumedTenant?: string }>(STORAGE_KEYS.AuthState) ??
        {}),
    });
  }

  setUser(user: User): void {
    this.update((state) => {
      state.user = user;
    });
  }

  setToken(token: string): void {
    this.update((state) => {
      state.cognitoToken = token;
    });
  }

  assumeTenant(tenant: string): void {
    this.update((state) => {
      state.assumedTenant = tenant;
    });
    this.storage.set(STORAGE_KEYS.AuthState, { assumedTenant: tenant });
  }
}

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  private cognitoToken$ = this.select((state) => state.cognitoToken).pipe(
    filterNil,
  );

  tenant$ = this.select((state) => state.assumedTenant || state.user?.tenant);

  token$ = this.cognitoToken$.pipe(
    switchMap((cognitoToken) => this.tokenProvider.getJwt(cognitoToken)),
  );

  constructor(store: AuthStore, private tokenProvider: TokenProvider) {
    super(store);
  }

  getUser(): User {
    return this.getValue().user;
  }

  isLoggedIn(): boolean {
    return !!this.getUser();
  }

  authorizedTenants(): string[] {
    return [
      'nexiot',
      'hupac',
      'sbbcargo',
      'medway',
      'genesee-wyoming',
      'touax',
    ];
  }
}
