import { Injectable } from '@angular/core';
import { CrossDomainService } from '../../@core/utils/cross-domain.service';

export interface Session {
  email?: string;
}

@Injectable({
  providedIn: 'root',
})
export class Session {
  constructor(private crossDomainService: CrossDomainService) {}

  async isLoggedIn(): Promise<boolean> {
    try {
      return await this.crossDomainService.isLoggedIn();
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async getToken(): Promise<string | undefined> {
    try {
      return await this.crossDomainService.getToken();
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }
}
