import { Injectable } from '@angular/core';
import { NbAclOptions, NbRoleProvider } from '@nebular/security';
import { Observable, of as observableOf } from 'rxjs';
import { AuthQuery } from '../../auth/state/auth.state';

type FrontendRole = 'user' | 'internal' | 'developer';

export const SECURITY_OPTIONS: NbAclOptions = {
  accessControl: {
    // regular user
    user: {},
    // nexxiot employee
    internal: {
      parent: 'user',
      assume: 'tenant',
      read: ['noise'],
    },
    // developer
    developer: {
      parent: 'internal',
    },
  },
};

@Injectable()
export class RoleProvider extends NbRoleProvider {
  constructor(protected authQuery: AuthQuery) {
    super();
  }

  getRole(): Observable<FrontendRole> {
    const roles = this.authQuery.getUser().roles;
    if (roles.includes('Nexiot:Developer')) {
      return observableOf('developer');
    }
    if (roles.some((ro) => ro.startsWith('Nexiot'))) {
      return observableOf('internal');
    }
    return observableOf('user');
  }
}
