import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { LayoutService, SeoService, StateService } from './utils';
import { HttpClientModule } from '@angular/common/http';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { CrossDomainService } from './utils/cross-domain.service';
import { NbDialogModule, NbWindowModule } from '@nebular/theme';
import { RoleProvider, SECURITY_OPTIONS } from './services/role.provider';

export const NB_CORE_PROVIDERS = [
  NbSecurityModule.forRoot(SECURITY_OPTIONS).providers,
  {
    provide: NbRoleProvider,
    useClass: RoleProvider,
  },
  LayoutService,
  SeoService,
  StateService,
  CrossDomainService,
  NbDateFnsDateModule.forRoot({
    format: 'dd.MM.yyyy',
    parseOptions: {
      useAdditionalWeekYearTokens: true,
      useAdditionalDayOfYearTokens: true,
    },
    formatOptions: {
      useAdditionalWeekYearTokens: true,
      useAdditionalDayOfYearTokens: true,
    },
  }).providers,
  NbDialogModule.forRoot().providers,
  NbWindowModule.forRoot().providers,
];

@NgModule({
  imports: [CommonModule, HttpClientModule],
  exports: [],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
