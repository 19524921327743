<div class="header">
  <div class="header-container">
    <div class="logo-container">
      <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
        <nb-icon icon="menu-outline"></nb-icon>
      </a>
      <a class="logo" href="#" (click)="navigateHome()">
        <img src="assets/images/nexxiot-white.png" />
      </a>
    </div>
    <nb-actions size="small">
      <nb-action
        title="Dashboard"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a
          class="icon-container"
          [routerLink]="'/dashboard'"
          [title]="'Dashboard'"
        >
          <nb-icon icon="activity-outline"></nb-icon>Dashboard
        </a>
      </nb-action>
      <nb-action
        title="Explorer"
        [routerLinkActive]="'active'"
      >
        <a
          class="icon-container"
          [routerLink]="'/explorer'"
          [title]="'Explorer'"
        >
          <nb-icon icon="bar-chart-outline"></nb-icon>Explorer
        </a>
      </nb-action>
      <nb-action
        title="Reports"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: false }"
      >
        <a
          class="icon-container"
          [routerLink]="'/reports'"
          [title]="'Reports'"
        >
          <nb-icon icon="file-text-outline"></nb-icon>Reports
        </a>
      </nb-action>
      <nb-action
        title="Feedback"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a
          class="icon-container"
          [routerLink]="'/feedback'"
          [title]="'Feedback'"
        >
          <nb-icon icon="message-square-outline"></nb-icon>Feedback
        </a>
      </nb-action>
    </nb-actions>
  </div>

  <div class="header-container">
    <nb-actions size="small">
      <nb-action>
        <a>
          <nb-icon
            icon="question-mark-circle-outline"
            (click)="openHelp(helpDialog)"
          ></nb-icon>
        </a>
      </nb-action>
      <nb-action *nbIsGranted="['assume', 'tenant']" >
        <button nbButton (click)="openTenantDialog()" >{{ tenant$ | async }}</button>
      </nb-action>

      <nb-action class="user-action">
        <nb-user
          [onlyPicture]="userPictureOnly"
          [showInitials]="true"
          [nbContextMenu]="userMenu"
          [size]="'small'"
          [name]="user?.name || user?.metadata?.name"
        >
        </nb-user>
      </nb-action>
    </nb-actions>
  </div>
</div>
<ngx-subnav></ngx-subnav>

<ng-template #helpDialog let-ref="dialogRef">
  <nb-card size="medium" style="width: 880px;" >
    <nb-card-header class="d-flex justify-content-between" >
      Help
      <nb-icon icon="close-outline" (click)="ref.close()" ></nb-icon>
    </nb-card-header>
    <nb-card-body>
      <h2>Features</h2>
      <h4>Peak</h4>
      <p>
        Peak is the highest absolute value of a shock curve.
      </p>
      <h4>Area</h4>
      <p>
        Area is the sum of all absolute values of a shock curve, multiplied by the gravitational acceleration (9.81 m/sˆ2).
        The area of a shock curve is expressed in meters per second (m/s).
      </p>
      <h4>RMS</h4>
      <p>
        <a href="https://en.wikipedia.org/wiki/Root_mean_square" target="_blank" >Root Mean Square</a> (AKA quadratic mean) is obtained by calculating the square root of the mean square of the values from a shock curve.
        Its value increases when the signal is distributed more on one side (positive or negative) than the other.
      </p>
    </nb-card-body>
  </nb-card>
</ng-template>
