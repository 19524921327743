import { TableColumn } from './shocks-table.interfaces';

export const columns: TableColumn[] = [
  {
    name: 'Time',
    prop: 'timestamp',
    sortable: true,
    template: 'time',
    show: true,
  },
  {
    name: 'Asset',
    prop: 'asset_id',
    sortable: false,
    template: 'asset',
    show: true,
  },
  { name: 'Wagon', sortable: false, template: 'wagon', show: true },
  { name: 'Prediction', sortable: false, template: 'prediction', show: true },
  { name: 'Address', sortable: false, template: 'address', show: false },
  { name: 'Speed', sortable: false, template: 'speed', show: true },
  {
    name: 'Motion state',
    sortable: false,
    template: 'motionState',
    show: false,
  },

  {
    name: 'Peak',
    sortable: true,
    prop: 'peak_gs',
    template: 'allAxis',
    show: false,
  },
  {
    name: 'Peak X',
    sortable: true,
    prop: 'peak_gs.x',
    template: 'singleAxis',
    show: true,
  },
  {
    name: 'Peak Y',
    sortable: true,
    prop: 'peak_gs.y',
    template: 'singleAxis',
    show: true,
  },
  {
    name: 'Peak Z',
    sortable: true,
    prop: 'peak_gs.z',
    template: 'singleAxis',
    show: true,
  },

  {
    name: 'Area',
    sortable: true,
    prop: 'area_gs_per_sec',
    template: 'allAxis',
    show: false,
  },
  {
    name: 'Area X',
    sortable: true,
    prop: 'area_gs_per_sec.x',
    template: 'singleAxis',
    show: false,
  },
  {
    name: 'Area Y',
    sortable: true,
    prop: 'area_gs_per_sec.y',
    template: 'singleAxis',
    show: false,
  },
  {
    name: 'Area Z',
    sortable: true,
    prop: 'area_gs_per_sec.z',
    template: 'singleAxis',
    show: false,
  },

  {
    name: 'Rms',
    sortable: true,
    prop: 'rms_gs',
    template: 'allAxis',
    show: false,
  },
  {
    name: 'Rms X',
    sortable: true,
    prop: 'rms_gs.x',
    template: 'singleAxis',
    show: false,
  },
  {
    name: 'Rms Y',
    sortable: true,
    prop: 'rms_gs.y',
    template: 'singleAxis',
    show: false,
  },
  {
    name: 'Rms Z',
    sortable: true,
    prop: 'rms_gs.z',
    template: 'singleAxis',
    show: false,
  },
];
