import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Channel, createGuest } from './cross-domain-channel';

@Injectable()
export class CrossDomainService implements OnDestroy {
  channel: Channel;

  constructor(@Inject(PLATFORM_ID) private platformId) {
    this.channel = createGuest(environment.connectUrl + '/auth/cross-domain');
  }

  isLoggedIn(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.channel.isLoggedIn((err, data) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }

  getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.channel.getToken((err, data) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(data);
      });
    });
  }

  ngOnDestroy() {
    this.channel && this.channel.close();
  }
}
