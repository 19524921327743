export const environment = {
  local: false,
  production: false,
  accessTokensApi:
    'https://av1.cloud.nexxiot-dev.net/nx/api-access-tokens/api/v1',
  shocksApi: 'https://av1.cloud.nexxiot-dev.net/nx/shocks-api/api/v1',
  snorlaxApi: 'https://av1.cloud.nexxiot-dev.net/nx/snorlax/api/v1',
  connectUrl: 'https://connect.preview.nexiot.ch',
  nexiotApi: '/api',
};
