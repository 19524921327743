import { Injectable } from '@angular/core';
import { AuthDataService } from './auth-data.service';
import { Session } from './session';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthQuery, AuthStore } from '../state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private session: Session,
    private authDataService: AuthDataService,
    private router: Router,
    private store: AuthStore,
    private query: AuthQuery,
  ) {
    this.session.getToken().then((token) => {
      if (!token) {
        this.handleTokenExpired();
        return;
      }
      this.store.setToken(token);
      this.schedulePageReload(token);
    });
  }

  /**
   * Called when an authenticated user reloads the page.
   */
  async restoreSession(): Promise<void> {
    try {
      const isLoggedIn = await this.session.isLoggedIn();
      if (!isLoggedIn) {
        this.handleTokenExpired();
        return;
      }
      const user = await this.authDataService.userInfo();
      const authorizedTenants = this.query.authorizedTenants();
      if (!authorizedTenants.includes(user.tenant)) {
        this.router.navigate(['unauthorized']);
      }
      this.store.setUser(user);
    } catch (e) {
      console.error(e);
      this.handleTokenExpired();
    }
  }

  /**
   * Redirects user to connect platform's sign-in page
   */
  private handleTokenExpired() {
    const redirect = `${location.pathname}${location.search}`;
    const loginUrl = `${
      environment.connectUrl
    }/auth/sign-in?app=shockpit&redirect=${encodeURIComponent(redirect)}`;
    console.log('Redirecting you to login');
    location.href = loginUrl;
  }

  /**
   * We simply reload the page when the access token expires (simple implementation)
   * @param token
   */
  private schedulePageReload(token: string) {
    if (!token) return;
    try {
      const [, payload] = token.split('.');
      const { exp } = JSON.parse(atob(payload));
      const secondsUntilExpired = exp - Math.floor(new Date().getTime() / 1000);
      setTimeout(function () {
        window.location.reload();
      }, secondsUntilExpired * 1000);
    } catch (err) {
      console.warn('error parsing token');
      console.error(err);
    }
  }
}
