import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { switchMap } from 'rxjs/operators';
import { AssetsService } from './@core/state/assets.state';
import { SeoService } from './@core/utils/seo.service';
import { MENU_ITEMS } from './app.sidemenu';
import { AuthQuery } from './auth/state/auth.state';
import { ShocksService } from './@core/services/shocks.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ngx-app',
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})
export class AppComponent implements OnInit {
  menu: NbMenuItem[] = MENU_ITEMS;

  constructor(
    private seoService: SeoService,
    private authQuery: AuthQuery,
    private assetService: AssetsService,
    private shocksService: ShocksService,
  ) {}

  ngOnInit(): void {
    this.seoService.trackCanonicalChanges();

    // autommatically load assets when tenant changes
    this.authQuery.tenant$
      .pipe(
        untilDestroyed(this),
        switchMap((tenant) => this.assetService.loadAssets(tenant)),
      )
      .subscribe();

    this.shocksService.loadVisited().subscribe();
    this.shocksService.loadBookmarks().subscribe();
  }
}
