import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import * as L from 'leaflet';
import { LeafletMouseEvent } from 'leaflet';
import {
  Cluster,
  ClusterInformation,
  ClustersDto,
  MapMove,
} from '../../../@core/model/shockpit.interfaces';
import { LayoutService } from '../../../@core/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as Geohash from 'ngeohash';
import * as wkt from 'wellknown';

@UntilDestroy()
@Component({
  selector: 'ngx-shock-map',
  styleUrls: ['./shock-map.component.scss'],
  template: `
    <div
      leaflet
      [leafletOptions]="options"
      (leafletMapReady)="mapReady($event)"
      (leafletMapMoveEnd)="onBoundsChange()"
    ></div>
    <ngx-search-control
      *ngIf="!!map && searchControl"
      [position]="'topleft'"
      [map]="map"
      (search)="search.emit($event)"
    ></ngx-search-control>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShockMapComponent implements OnInit {
  @Input()
  set focus(location: { lat: number; lng: number } | undefined) {
    if (this.map && location) {
      this.map.flyTo([location.lat, location.lng], 17, {
        animate: false,
      });
    }
  }

  @Input() set clusters(clusters: ClustersDto) {
    if (clusters) {
      this.renderClusters(clusters.clusters);
    }
  }

  @Input() searchControl = true;

  @Output() clusterSelect = new EventEmitter<ClusterInformation>();

  @Output() mapMove = new EventEmitter<MapMove>();

  @Output() search = new EventEmitter<L.LatLngBounds | undefined>();

  map: L.Map;
  markers: L.LayerGroup = L.layerGroup();

  options = {
    zoom: 4,
    minZoom: 2,
    maxZoom: 18,
    zoomControl: false,
    center: L.latLng({ lat: 47.2610662, lng: 4.7944448 }),
    maxBounds: new L.LatLngBounds(
      new L.LatLng(-89.98155760646617, -180),
      new L.LatLng(89.99346179538875, 180),
    ),
    maxBoundsViscosity: 1.0,
    layers: [
      L.tileLayer(
        'https://api.maptiler.com/maps/056526fb-0b5e-4f9f-9135-168ee19715ba/256/{z}/{x}/{y}@2x.png?key=rAxiEAd6UJTPeswnkRGe',
        { maxZoom: 18 },
      ),
    ],
  };

  constructor(private layout: LayoutService) {}

  mapReady(map: L.Map): void {
    this.map = map;
    this.map.addControl(L.control.zoom({ position: 'bottomright' }));
    // fix the map fully displaying, existing leaflet bag
    setTimeout(() => {
      this.map.invalidateSize();
      this.mapMove.next({
        bounds: this.map.getBounds(),
        zoom: this.map.getZoom(),
      });
    }, 0);
  }

  ngOnInit(): void {
    this.layout
      .onSafeChangeLayoutSize()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.map && this.map.invalidateSize();
      });
  }

  private renderClusters(clusters: Cluster[]): void {
    this.markers.clearLayers();
    clusters.forEach((cluster) => {
      if (cluster.centroid == null) return;
      const markerWithData = L.Marker.extend({
        cluster: {},
      });
      const [lon, lat] = wkt.parse(cluster.centroid).coordinates as [
        number,
        number,
      ];
      const marker = new markerWithData(L.latLng(lat, lon), {
        icon: new L.DivIcon({
          className: 'shock-cluster',
          html: `<span>${cluster.count}</span>`,
          iconSize: [40, 40],
          iconAnchor: [20, 20],
          popupAnchor: [0, 0],
        }),
        cluster: cluster,
      })
        .bindPopup(`<span>Loading...</span>`)
        .addEventListener('click', (event: LeafletMouseEvent) => {
          const bbox = Geohash.decode_bbox(
            event.target.options.cluster.geohash,
          );
          const popup = event.target.getPopup();

          this.clusterSelect.emit({
            bbox,
            popup,
          });
        });
      this.markers.addLayer(marker);
    });
    this.markers.addTo(this.map);
  }

  onBoundsChange(): void {
    this.mapMove.next({
      bounds: this.map.getBounds(),
      zoom: this.map.getZoom(),
    });
  }

  // private getSiblings(elem) {
  //   // Setup siblings array and get the first sibling
  //   const siblings = [];
  //   let sibling = elem.parentNode.firstChild;

  //   // Loop through each sibling and push to the array
  //   while (sibling) {
  //     if (sibling.nodeType === 1 && sibling !== elem) {
  //       siblings.push(sibling);
  //     }
  //     sibling = sibling.nextSibling;
  //   }

  //   return siblings;
  // }
}
