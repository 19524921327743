import { Pipe, PipeTransform } from '@angular/core';
import { CapitalizePipe } from './capitalize.pipe';

const predictionMap = {
  loading: 'Load drop',
  moving: 'Rail tracks',
  shunting: 'Shunting',
};

@Pipe({
  name: 'predictionName',
})
export class PredictionNamePipe implements PipeTransform {
  transform(prediction: string): string {
    const capitalizePipe = new CapitalizePipe();
    return Object.keys(predictionMap).some((p) => p === prediction)
      ? predictionMap[prediction]
      : capitalizePipe.transform(prediction);
  }
}
