import { NbMenuItem } from '@nebular/theme';
import { environment } from '../environments/environment';

// See: https://akveo.github.io/nebular/docs/components/menu/api#nbmenucomponent
export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'APPS',
    group: true,
    expanded: true,
  },
  {
    title: 'Connect Platform',
    url: environment.connectUrl,
    target: '_blank',
    icon: 'globe-2-outline',
  },
  {
    title: 'Shockpit',
    link: '/',
    icon: 'activity-outline',
    home: true,
    selected: true,
  },
];
