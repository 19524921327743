import { Axis, EachAxis, Shock } from '../model/shockpit.interfaces';
import { subMilliseconds } from 'date-fns';
import { ShockTableItem } from '../../@theme/components/shocks-table/shocks-table.interfaces';

export function isNil(value: unknown): value is null | undefined {
  return value === undefined || value === null;
}

export function isPrimitive(
  value: unknown,
): value is string | number | boolean {
  return isString(value) || isNumber(value) || isBool(value);
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number' && !isNaN(value);
}

export function isBool(value: unknown): value is boolean {
  return typeof value === 'boolean';
}

export function isDate(value: unknown): value is Date {
  return value instanceof Date && !isNaN(+value);
}

export function snakeCase(str: string): string {
  const upperChars = str.match(/([A-Z])/g);
  if (!upperChars) {
    return str;
  }
  for (const upperChar of upperChars) {
    str = str.replace(new RegExp(upperChar), '_' + upperChar.toLowerCase());
  }
  if (str.slice(0, 1) === '_') {
    str = str.slice(1);
  }
  return str;
}

export function snakeCaseKeys<T = Record<string, unknown>>(obj: T): T {
  return Object.keys(obj).reduce((acc, key) => {
    acc[snakeCase(key)] = obj[key];
    return acc;
  }, {} as T);
}

export function pickBy(
  obj: Record<string, unknown>,
  callback: (o: any) => boolean,
): Record<string, unknown> {
  return Object.keys(obj).reduce((acc, key) => {
    if (callback(obj[key])) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export function mean(data: number[]): number {
  return (
    data.reduce(function (a, b) {
      return a + b;
    }, 0) / data.length
  );
}

export function median(data: number[]): number {
  const mid = Math.floor(data.length / 2),
    nums = [...data].sort((a, b) => a - b);
  return data.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
}

export function sd(data: number[]): number {
  const m = mean(data);
  return Math.sqrt(
    data.reduce(function (sq, n) {
      return sq + Math.pow(n - m, 2);
    }, 0) /
      (data.length - 1),
  );
}

/**
 * maps the tri-dimensional object into another given a callback function
 * @param obj
 * @param callback
 */
export function mapAxis<T, C = number[]>(
  obj: EachAxis<C>,
  callback: (curve: C, a: Axis) => T,
): EachAxis<T> {
  const { x, y, z } = obj;
  return Object.keys({ x, y, z }).reduce((acc, axis) => {
    acc[axis] = callback(obj[axis], axis as Axis);
    return acc;
  }, {} as EachAxis<T>);
}

/**
 * creates object with the 3 axis
 * @param callback
 */
export function makeAxis<T>(callback: (axis: Axis) => T): EachAxis<T> {
  return ['x', 'y', 'z'].reduce((acc, curr: Axis) => {
    acc[curr] = callback(curr);
    return acc;
  }, {} as EachAxis<T>);
}

/**
 * Unifies the curve of the 3 axis into 1 [...x, ...y, ...z]
 */
export function flattenAxis<T, R>(
  { x, y, z }: EachAxis<T>,
  callback: (value: T, a: Axis) => R,
): R[] {
  return [callback(x, 'x'), callback(y, 'y'), callback(z, 'z')];
}

export function flatten<T>(arr: T[][]): T[] {
  return ([] as T[]).concat(...arr);
}

export function shockId(shock: Shock | ShockTableItem): string {
  return shock.id;
}

export function parseDateToUTC(date: Date): Date {
  return subMilliseconds(date, new Date().getTimezoneOffset() * 60000);
}

export function squarePolygonWkt(
  [s, w]: [number, number],
  [n, e]: [number, number],
): string {
  return `POLYGON((${w} ${s}, ${w} ${n}, ${e} ${n}, ${e} ${s}, ${w} ${s}))`;
}

export function tryOr<R, T>(fn: () => R, or: T): R | T {
  try {
    return fn();
  } catch {
    return or;
  }
}
