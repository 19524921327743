<div class="shock-actions">
  <button
    nbButton
    nbTooltip="Share"
    ghost
    status="primary"
    (click)="shareShock()"
  >
    <nb-icon
      [icon]="'share-outline'"
    ></nb-icon>
  </button>
  <button
    nbButton
    nbTooltip="Bookmark"
    ghost
    status="warning"
    (click)="toggleBookmark.emit()"
  >
    <nb-icon
      [icon]="bookmarked ? 'bookmark' : 'bookmark-outline'"
    ></nb-icon>
  </button>
</div>
<div class="shock-description">
  <div>
    <label>Peak</label>
    <ngx-axis-values
      [x]="shock.peak_gs.x | number:'1.0-1'"
      [y]="shock.peak_gs.y | number:'1.0-1'"
      [z]="shock.peak_gs.z | number:'1.0-1'"
    ></ngx-axis-values>
  </div>
  <div>
    <label>Area</label>
    <ngx-axis-values
      [x]="shock.area_gs_per_sec.x | number:'1.0-1'"
      [y]="shock.area_gs_per_sec.y | number:'1.0-1'"
      [z]="shock.area_gs_per_sec.z | number:'1.0-1'"
    ></ngx-axis-values>
  </div>
  <div>
    <label>RMS</label>
    <ngx-axis-values
      [x]="shock.rms_gs.x | number:'1.0-1'"
      [y]="shock.rms_gs.y | number:'1.0-1'"
      [z]="shock.rms_gs.z | number:'1.0-1'"
    ></ngx-axis-values>
  </div>
</div>
<div
  echarts
  [options]="options"
  (chartInit)="onChartInit($event)"
  class="echart"
></div>
