import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import {
  Circle,
  Control,
  ControlPosition,
  DomUtil,
  LatLngBounds,
  Map,
  Polygon,
  Rectangle,
} from 'leaflet';
import {
  GeofenceDataService,
  Geofence,
} from '../../../../@core/services/geofence-data.service';
import { GeofenceDialogComponent } from './geofence-dialog.component';

@Component({
  selector: 'ngx-search-control',
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.scss'],
})
export class SearchControlComponent implements OnDestroy {
  private _map: Map;
  public custom: Control;

  public searchPolygon: Polygon;
  public geofencePolygon: Polygon | Rectangle | Circle;

  @Input() position: ControlPosition = 'topleft';

  get map(): Map {
    return this._map;
  }

  @Input() set map(map: Map) {
    if (map) {
      this._map = map;
      const Custom = Control.extend({
        onAdd() {
          return DomUtil.get('search-control');
        },
      });
      this.custom = new Custom({
        position: this.position,
      }).addTo(map);
    }
  }

  get searchActive(): boolean {
    return !!this.searchPolygon;
  }

  @Output() search = new EventEmitter<LatLngBounds | undefined>();

  constructor(
    private dialog: NbDialogService,
    private geofenceService: GeofenceDataService,
  ) {}

  ngOnDestroy(): void {
    this._map.removeControl(this.custom);
  }

  onSearch(): void {
    const bounds = this._map.getBounds();
    this.clearSearchPolygon();
    this.setSearch(bounds);
  }

  onSearchByGeofence(): void {
    this.dialog
      .open(GeofenceDialogComponent)
      .onClose.subscribe((geofence?: Geofence) => {
        if (!geofence) return;
        // remove previous search
        this.clearSearchPolygon();
        this.geofencePolygon = this.geofenceService.getPolygon(
          geofence.geometry,
        );
        // this L.Circle needs to have been added to the map in order to call `.getBounds()`
        this.geofencePolygon.addTo(this._map);
        setTimeout(() => {
          this.setSearch(this.geofencePolygon.getBounds());
        }, 500);
      });
  }

  setSearch(bounds: LatLngBounds): void {
    this.search.emit(bounds);
    this.searchPolygon = new Polygon(
      [
        [
          [90, -180],
          [90, 180],
          [-90, 180],
          [-90, -180],
        ], // outer ring
        [...new Rectangle(bounds).getLatLngs()], // actual cutout polygon
      ],
      { fillColor: 'f7f9fc', color: '000000' },
    ).addTo(this._map);
    setTimeout(() => {
      this.map.flyToBounds(bounds, { animate: false });
    }, 1000);
  }

  clearSearch(): void {
    this.search.emit();
    this.clearSearchPolygon();
  }

  private clearSearchPolygon() {
    if (this.searchPolygon) {
      this.searchPolygon.remove();
      this.searchPolygon = null;
    }
    if (this.geofencePolygon) {
      this.geofencePolygon.remove();
      this.geofencePolygon = null;
    }
  }
}
