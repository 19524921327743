import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { environment } from '../../../../environments/environment';
import { User } from '../../../auth/models/user';
import { UserService } from '../../../@core/services/user.service';

@Component({
  selector: 'ngx-user-badge',
  templateUrl: './user-badge.component.html',
  styleUrls: ['./user-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserBadgeComponent implements OnInit {
  @Input() userId: string;
  loading: boolean;
  user?: User;

  constructor(
    protected userService: UserService,
    protected cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadUser();
  }

  loadUser() {
    this.loading = true;
    this.userService
      .getUser(this.userId)
      .then((user) => {
        this.user = user;
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {
        this.loading = false;
        this.cd.detectChanges();
      });
  }

  getProfileUrl(): string {
    return `${environment.connectUrl}/users/${this.userId}`;
  }
}
