<ngx-datatable
  class="material"
  rowHeight="auto"
  [rows]="rows$ | async"
  [messages]="{ emptyMessage: 'No shocks', selectedMessage: ''}"
  [columnMode]="ColumnMode.flex"
  [rowClass]="getRowClass"
  [selectionType]="selectionType"
  [externalPaging]="true"
  [externalSorting]="true"
  [headerHeight]="50"
  [footerHeight]="footer ? 50 : undefined"
  [limit]="footer ? 10 : undefined"
  [count]="total ?? (rows$ | async)?.length"
  [offset]="pageInfo.page - 1"
  [sorts]="[{prop: 'timestamp', dir: 'desc'}]"
  [loadingIndicator]="isLoading"
  (select)="rowClick.emit($event.selected[0])"
  (sort)="sortCallback($event)"
  (page)="changePageCallback($event)"
>
  <ngx-datatable-column
    *ngFor="let col of columns$ | async"
    [name]="col.name"
    [sortable]="col.sortable"
    [cellTemplate]="_templates[col.template]"
    [prop]="col.prop"
    [resizeable]="false"
    [flexGrow]="1"
  ></ngx-datatable-column>

  <ngx-datatable-column
    [headerClass]="'text-right'"
    [cellClass]="'justify-content-end'"
    [frozenRight]="true"
    [flexGrow]="1"
    [resizeable]="false"
    [sortable]="false"
  >
    <ng-template ngx-datatable-header-template>
      <nb-icon [icon]="'settings-2-outline'" (click)="openConfigModal()"></nb-icon>
    </ng-template>
    <ng-template ngx-datatable-cell-template let-row="row">
      <div class="details-container">
        <nb-icon
          *ngIf="row.bookmarked"
          class="text-warning"
          [icon]="'bookmark'"
          nbTooltip="Bookmarked"
        ></nb-icon>
        <nb-icon
          *ngIf="!row.location"
          class="text-warning"
          [icon]="'wifi-off-outline'"
          nbTooltip="No location"
        ></nb-icon>
        <nb-icon
          *ngIf="row.is_noise"
          class="text-danger"
          [icon]="'alert-triangle-outline'"
          [nbTooltip]="'Classified as noise'"
        ></nb-icon>
        <nb-icon
          *ngIf="row.has_feedback"
          class="text-info"
          [icon]="'message-circle-outline'"
          [nbTooltip]="'Manually classified'"
        ></nb-icon>
        <button
          nbButton
          hero="light"
          size="small"
          (click)="addShockIdInQueryParams(row, $event)"
        >
          DETAILS
        </button>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<ng-template templateId='time' let-row="row">
  {{ row.ts | date: "dd.MM.yyyy HH:mm:ss" }}
</ng-template>

<ng-template templateId='asset' let-row="row">
  <div class="asset-link">
    <a [routerLink]="['/assets', row.asset_id]" (click)="$event.stopPropagation()">
      {{ row.asset_id | assetName | async }}
    </a>
    <span>{{ row.asset_id }}</span>
  </div>
</ng-template>

<ng-template templateId='wagon' let-row="row">
  <ngx-wagon-column [assetId]="row.asset_id" ></ngx-wagon-column>
</ng-template>

<ng-template templateId='prediction' let-row="row" let-value="value">
  <span *ngIf="!value; else predictionTpl">
    PROCESSING...
  </span>
  <ng-template #predictionTpl >
    {{ value.kind | predictionName }} <span style="color: #8f9bb3" > {{ value.probability | percent: '1.0-1' }}</span>
  </ng-template>
</ng-template>

<ng-template templateId='address' let-row="row">
  <div style="position: relative;" *ngIf="row.address?.displayName; else noAddress" >
    <span [nbPopover]="row.address?.displayName" nbPopoverTrigger="hover" >
      {{ row.address?.displayName | slice:0:20 }}...
    </span>
  </div>

  <ng-template #noAddress>
    <div style="position: relative;color: #8f9bb3" >
      {{ row.location ? (row.location | location) : 'NO LOCATION' }}
    </div>
  </ng-template>
</ng-template>

<ng-template templateId='speed' let-row="row">
  <div *ngIf="row.speed !== null && row.speed !== undefined" >
    {{ row.speed | number: '1.0-1' }} <span style="color: #8f9bb3" >km/h</span>
  </div>
</ng-template>

<ng-template templateId='motionState' let-row="row">
  {{ row.motion_state }}
</ng-template>

<ng-template templateId='allAxis' let-value="value" >
  <ngx-axis-values
    [x]="value.x | number: '1.0-1'"
    [y]="value.y | number: '1.0-1'"
    [z]="value.z | number: '1.0-1'"
  ></ngx-axis-values>
</ng-template>

<ng-template templateId='singleAxis' let-value="value" >
  {{ value | number: '1.0-1' }}
</ng-template>
