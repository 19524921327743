import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssetsQuery } from '../../@core/state/assets.state';

@Pipe({
  name: 'assetName',
})
export class AssetNamePipe implements PipeTransform {
  constructor(private assetsQuery: AssetsQuery) {}

  transform(assetId: string): Observable<string> {
    return this.assetsQuery
      .selectEntity(assetId)
      .pipe(map((asset) => asset?.asset_name || ''));
  }
}
