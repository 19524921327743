<div class="breadcrumbs">
  <a [routerLink]="['/']" >
    <nb-icon icon="activity-outline" pack="eva"></nb-icon>
    {{ user?.tenant === "nexiot" ? "nexxiot" : user?.tenant }}
    <nb-icon icon="chevron-right"></nb-icon>
  </a>
  <a [routerLink]="b.routerLink" *ngFor="let b of breadcrumbs$ | async; let last = last" >
    {{ b.title }}
    <nb-icon *ngIf="!last" icon="chevron-right"></nb-icon>
  </a>
</div>
<div [hidden]="(showTimeRangeFilter$ | async) === false" >
  <ngx-rangepicker (rangeChange)="onTimeRangeChange($event)" ></ngx-rangepicker>
</div>
