<div id="search-control" class="btn-group" >
  <button
    class="btn search-button"
    [nbPopover]="searchPopover"
    nbPopoverPlacement="bottom"
    nbPopoverTrigger="click" 
  >
    Search
  </button>
  <button *ngIf="searchActive" class="btn clear-button" (click)="clearSearch()">
    Clear
  </button>
</div>

<ng-template #searchPopover>
  <nb-card class="m-0">
    <nb-list>
      <nb-list-item (click)="onSearch()" >This area</nb-list-item>
      <nb-list-item (click)="onSearchByGeofence()">Geofence</nb-list-item>
    </nb-list>
  </nb-card>
</ng-template>