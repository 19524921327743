<span *ngIf="loading; else avatar">Loading...</span>

<ng-template #avatar>
  <button
    type="button"
    *ngIf="user"
    nbButton
    [nbPopover]="menu"
    nbPopoverClass="user-badge-popover"
    size="tiny"
  >
    {{ user?.name || user?.metadata?.name || user?.email }}
  </button>
  <button type="button" *ngIf="!user" nbButton disabled size="small" class="text-danger">
    user has been deleted
  </button>
</ng-template>

<ng-template #menu>
  <nb-card class="m-0">
    <nb-list>
      <nb-list-item>
        <a [href]="getProfileUrl()" target="_blank">
          <span>Profile</span>
          <nb-icon icon="external-link-outline"></nb-icon>
        </a>
      </nb-list-item>
      <nb-list-item>
        <a [href]="'mailto:' + user?.email" target="_blank">
          <span>{{ user?.email }}</span>
          <nb-icon icon="email-outline"></nb-icon>
        </a>
      </nb-list-item>
    </nb-list>
  </nb-card>
</ng-template>
