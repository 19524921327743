import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, combineLatest, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { AuthQuery } from '../state/auth.state';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authQuery: AuthQuery) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return combineLatest([this.authQuery.token$, this.authQuery.tenant$]).pipe(
      take(1),
      mergeMap(([token, tenant]) => {
        if (token) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache',
              ...(tenant ? { 'X-Nx-Tenant': tenant } : {}),
            },
          });
        }
        return next.handle(request);
      }),
    );
  }
}
