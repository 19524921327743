<nb-card>
  <nb-card-header>
    Select options
  </nb-card-header>
  <nb-card-body>
    <div class="dynamic-columns-selector">
      <div *ngFor="let col of allColumns" class="p-2 dynamic-item">
        <nb-checkbox [(checked)]="col.show">
          {{ col.name }}
        </nb-checkbox>
      </div>
    </div>
    <span class="text-danger p-2" *ngIf="error">No Columns Selected</span>
  </nb-card-body>
  <nb-card-footer class="d-flex justify-content-end">
    <button class="btn btn-primary ml-2" type="submit" (click)="save()">
      Save
    </button>
  </nb-card-footer>
</nb-card>
