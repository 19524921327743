import {
  Component,
  ElementRef,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { NbCalendarRange } from '@nebular/theme';
import {
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns';

type States = 'today' | 'week' | 'month' | 'year' | 'custom';

@Component({
  selector: 'ngx-rangepicker',
  templateUrl: './rangepicker.component.html',
  styleUrls: ['rangepicker.component.scss'],
})
export class RangePickerComponent implements OnInit {
  @Output() rangeChange = new EventEmitter<NbCalendarRange<Date>>();

  ngModel: NbCalendarRange<Date>;

  selected: States;

  @ViewChild('rangePickerInput') input: ElementRef<HTMLInputElement>;

  get display(): string {
    return this.input?.nativeElement?.value || '';
  }

  ngOnInit(): void {
    this.setRange('month', false);
  }

  setRange(type: States, emit = true): void {
    this.selected = type;
    const end = endOfDay(new Date());
    // return today, if param is bigger than today.
    switch (type) {
      case 'today':
        this.ngModel = { start: startOfDay(new Date()), end };
        emit && this.rangeChange.emit(this.ngModel);
        break;
      case 'week':
        this.ngModel = { start: startOfWeek(new Date()), end };
        emit && this.rangeChange.emit(this.ngModel);
        break;
      case 'month':
        this.ngModel = { start: startOfMonth(new Date()), end };
        emit && this.rangeChange.emit(this.ngModel);
        break;
      case 'year':
        this.ngModel = { start: startOfYear(new Date()), end };
        emit && this.rangeChange.emit(this.ngModel);
        break;
      case 'custom':
        // opens datepicker
        this.input.nativeElement.focus();
        break;
    }
  }

  /**
   * Triggered when the user click on start AND when the user clicks on end.
   * @param range
   */
  timeRangeChange(range: NbCalendarRange<Date>): void {
    // only change filters on end events
    if (range.end) {
      // this.input.nativeElement.blur()
      this.rangeChange.emit(range);
    }
  }

  enabledDateRange(date: Date): boolean {
    return date <= new Date();
  }
}
