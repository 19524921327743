import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="m-auto">
      <b>Shockpit</b> Powered by
      <b><a href="https://nexxiot.com" target="_blank">Nexxiot</a></b>
      {{ currentYear }}
    </span>
  `,
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
}
