import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '../../auth/models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl = environment.nexiotApi;

  constructor(protected http: HttpClient) {}

  getUser(userId: string): Promise<User> {
    const fields =
      'user_id,sub,email,tenant,tenant_id,metadata,permissions,features,settings,configuration,assumed_tenant,assumed_configuration,environment,system_data,roles,assumed_group,assumed_group_id,is_developer';
    return this.http
      .get<User>(`${this.baseUrl}/v1/users/def/${userId}`, {
        params: { fields },
      })
      .toPromise();
  }
}
