<ng-template #formRef>
  <nb-alert status="danger" *ngIf="err">{{ err }}</nb-alert>
  <form
    [formGroup]="form"
    novalidate
    (ngSubmit)="save()"
    (change)="onFormChanged()"
  >
    <p>Your feedback helps us improve our shock classification algorithm</p>
    <div class="form-group">
      <nb-toggle labelPosition="right" formControlName="interesting">
        {{
          form.get("interesting").value
            ? "This shock is interesting!"
            : "Ignore this shock"
        }}
      </nb-toggle>
    </div>
    <div class="form-group">
      <label class="label">Classification</label>
      <nb-select class="form-control" formControlName="category">
        <nb-option [value]="null">N/A</nb-option>
        <nb-option [value]="Classification.Shunting">
          {{ Classification.Shunting }}</nb-option
        >
        <nb-option [value]="Classification.Derailment">{{
          Classification.Derailment
        }}</nb-option>
        <nb-option [value]="Classification.LoadDrop">{{
          Classification.LoadDrop
        }}</nb-option>
      </nb-select>
    </div>
    <div class="form-group">
      <label class="label">Damage</label>
      <nb-select class="form-control" formControlName="damage">
        <nb-option [value]="null">N/A</nb-option>
        <nb-option [value]="DamageType.Bearings">{{
          DamageType.Bearings
        }}</nb-option>
        <nb-option [value]="DamageType.Buffers">{{
          DamageType.Buffers
        }}</nb-option>
        <nb-option [value]="DamageType.Wheelset">{{
          DamageType.Wheelset
        }}</nb-option>
      </nb-select>
    </div>
    <div class="form-group">
      <label class="label">Additional information</label>
      <textarea
        nbInput
        fullWidth
        class="form-control"
        formControlName="description"
      ></textarea>
    </div>
    <div>
      <a (click)="toggleMoreDetails()" class="details-toggler">
        {{ moreDetails ? 'Less' : 'More' }} Details
      </a>
      <ng-container *ngIf="moreDetails">
        <ng-template #historyLoader>
          <nb-spinner [size]="'tiny'" class="size-medium status-basic"></nb-spinner>
        </ng-template>
        <ul class="feedback-revisions" *ngIf="feedbackRevisions; else historyLoader">
          <ng-container *ngIf="feedbackRevisions.revisions.length === 0; else feedbackHistoryList" >
            <li>No feedback submitted</li>
          </ng-container>
          <ng-template #feedbackHistoryList >
            <li *ngFor="let feedback of feedbackRevisions.revisions">
              <div>{{ feedback.created_at | date: "dd.MM.yyyy HH:mm:ss" }}</div>
              <ngx-user-badge [userId]="feedback.created_by"></ngx-user-badge>
              <div>{{ feedback.feedback.category }}</div>
            </li>
          </ng-template>
        </ul>
      </ng-container>
    </div>
    <div class="form-footer">
      <div *ngIf="feedbackRevisions.revisions?.[0] as lastRevision" class="last-modified">
        Last modified by
        <ngx-user-badge [userId]="lastRevision.created_by"></ngx-user-badge> on
        {{ lastRevision.created_at | date: "dd.MM.yyyy HH:mm:ss" }}
      </div>
      <button nbButton [disabled]="form.invalid || loading">Save</button>
    </div>
  </form>

  <div class="thank-you" *ngIf="thankYou" >
    Thank you for your feedback!
  </div>
</ng-template>

<div class="h-100" *ngIf="loading; else formRef">
  <ngx-loading></ngx-loading>
</div>
