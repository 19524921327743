import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { NbThemeService, NbToastrService } from '@nebular/theme';
import { EChartOption, ECharts } from 'echarts';
import { LayoutService } from '../../../../@core/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ShockTableItem } from '../../shocks-table/shocks-table.interfaces';
import { Clipboard } from '@angular/cdk/clipboard';

@UntilDestroy()
@Component({
  selector: 'ngx-shock-curve-graph',
  templateUrl: './shock-curve-graph.component.html',
  styleUrls: ['./shock-curve-graph.component.scss'],
})
export class ShockCurveGraphComponent implements OnInit, AfterViewInit {
  @Input() shock: ShockTableItem;
  @Input() bookmarked = false;
  @Output() toggleBookmark = new EventEmitter<void>();

  options: EChartOption = {};
  echartsInstance: ECharts;

  constructor(
    private layoutService: LayoutService,
    private theme: NbThemeService,
    private toastr: NbToastrService,
    private clipboard: Clipboard,
  ) {}

  ngOnInit(): void {
    this.layoutService
      .onSafeChangeLayoutSize()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.resizeChart());
  }

  ngAfterViewInit(): void {
    this.theme
      .getJsTheme()
      .pipe(untilDestroyed(this))
      .subscribe((config) => {
        const colors: any = config.variables;
        const echarts: any = config.variables.echarts;

        this.options = {
          backgroundColor: echarts.bg,
          color: [colors.dangerLight, colors.successLight, colors.infoLight],
          tooltip: {
            trigger: 'none',
            axisPointer: {
              type: 'cross',
            },
          },
          legend: {
            data: ['X axis', 'Y axis', 'Z axis'],
            textStyle: {
              color: echarts.textColor,
            },
          },
          grid: {
            top: 70,
            bottom: 50,
          },
          xAxis: [
            {
              type: 'category',
              axisTick: {
                alignWithLabel: true,
              },
              axisLine: {
                onZero: false,
                lineStyle: {
                  color: echarts.textColor,
                },
              },
              axisPointer: {
                label: {
                  formatter: (params) => this.formatLabel(params),
                },
              },
              data: this.shock.curve_gs.x.map(
                (_, i) =>
                  `${((i * 1000) / this.shock.curve_gs.x.length).toFixed(
                    0,
                  )} ms`,
              ),
            },
          ],
          yAxis: [
            {
              type: 'value',
              axisLine: {
                lineStyle: {
                  color: echarts.axisLineColor,
                },
              },
              splitLine: {
                lineStyle: {
                  color: echarts.splitLineColor,
                },
              },
            },
          ],
          series: [
            {
              name: 'X axis',
              type: 'line',
              smooth: true,
              data: this.shock.curve_gs.x,
            },
            {
              name: 'Y axis',
              type: 'line',
              smooth: true,
              data: this.shock.curve_gs.y,
            },
            {
              name: 'Z axis',
              type: 'line',
              smooth: true,
              data: this.shock.curve_gs.z,
            },
          ],
        };
      });
  }

  onChartInit(ec: ECharts): void {
    this.echartsInstance = ec;
  }

  resizeChart(): void {
    if (this.echartsInstance) {
      this.echartsInstance.resize();
    }
  }

  private formatLabel(params): string {
    const x = params.seriesData?.[0];
    const y = params.seriesData?.[1];
    const z = params.seriesData?.[2];
    let label = '';
    if (x) {
      label += `${x.seriesName}: ${x.data === undefined ? 'N/a' : x.data}\n`;
    }
    if (y) {
      label += `${y.seriesName}: ${y.data === undefined ? 'N/a' : y.data}\n`;
    }
    if (z) {
      label += `${z.seriesName}: ${z.data === undefined ? 'N/a' : z.data}`;
    }
    return label;
  }

  shareShock(): void {
    this.clipboard.copy(location.href);
    this.toastr.info('Link copied to the clipboard', 'Clipboard');
  }
}
