import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-slide-out',
  styleUrls: ['./slide-out.component.scss'],
  templateUrl: './slide-out.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideOutComponent {
  @Input() show = false;

  toggle() {
    this.show = !this.show;
  }
}
