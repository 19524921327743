import { Pipe, PipeTransform } from '@angular/core';
import { differenceInDays, Interval } from 'date-fns';
import { PaginatedShocks } from '../../@core/model/shockpit.interfaces';
import { isNil } from '../../@core/utils';

@Pipe({
  name: 'shockIndex',
})
export class ShockIndexPipe implements PipeTransform {
  transform(
    shocks: PaginatedShocks,
    interval: Interval,
    fleetSize: number,
  ): number {
    if (isNil(shocks)) return;
    if (isNil(fleetSize)) return;
    if (isNil(interval)) return;

    const nDays = differenceInDays(interval.end, interval.start);
    if (fleetSize === 0 || nDays === 0) return 0;
    return shocks.count / fleetSize / nDays;
  }
}
