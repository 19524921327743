import { Component, OnInit } from '@angular/core';
import { User } from '../../../auth/models/user';
import { NbCalendarRange } from '@nebular/theme';
import { endOfDay, startOfDay } from 'date-fns';
import { SubnavService } from './subnav.service';
import { AuthQuery } from '../../../auth/state/auth.state';
import { ShocksService } from '../../../@core/services/shocks.service';

@Component({
  selector: 'ngx-subnav',
  styleUrls: ['./subnav.component.scss'],
  templateUrl: './subnav.component.html',
})
export class SubnavComponent implements OnInit {
  user: User;

  breadcrumbs$ = this.subnavService.breadcrumbs$;

  showTimeRangeFilter$ = this.subnavService.showTimeRangeFilter$;

  constructor(
    private authQuery: AuthQuery,
    private shocksService: ShocksService,
    private subnavService: SubnavService,
  ) {}

  ngOnInit(): void {
    this.user = this.authQuery.getUser();
  }

  /**
   * Triggered when the user click on start AND when the user clicks on end.
   * @param range
   */
  onTimeRangeChange({ start, end }: NbCalendarRange<Date>): void {
    this.shocksService.patchFilter({
      start: startOfDay(start),
      end: endOfDay(end),
    });
  }
}
