import { Injectable } from '@angular/core';
import { isNil } from './functions';

export enum STORAGE_KEYS {
  AuthState = 'authState',
  DashboardState = 'dashboardState',
  ShocksTableState = 'shocksTableState',
}
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  clear(): void {
    window.localStorage.clear();
  }

  get<T>(key: string): T | undefined {
    try {
      return JSON.parse(window.localStorage.getItem(key));
    } catch (err) {
      return undefined;
    }
  }

  remove(key: string): void {
    window.localStorage.removeItem(key);
  }

  set<T = unknown>(key: string, value: T): void {
    window.localStorage.setItem(
      key,
      isNil(value) ? null : JSON.stringify(value),
    );
  }
}
