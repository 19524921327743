import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface BreadcrumbPath {
  routerLink: string[];
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class SubnavService {
  private breadcrumbs = new BehaviorSubject<BreadcrumbPath[]>([]);
  breadcrumbs$ = this.breadcrumbs.asObservable();

  private showTimeRangeFilter = new BehaviorSubject<boolean>(false);
  showTimeRangeFilter$ = this.showTimeRangeFilter.asObservable();

  setBreadcrumbs(paths: BreadcrumbPath[]): void {
    this.breadcrumbs.next(paths);
  }

  timeRangeFilterVisible(show: boolean): void {
    this.showTimeRangeFilter.next(show);
  }
}
