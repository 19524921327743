import { LatLngBounds, Popup } from 'leaflet';
import { GeographicBoundingBox } from 'ngeohash';

export type Axis = 'x' | 'y' | 'z';

export enum Feature {
  PEAK = 'peak',
  AREA = 'area',
  RMS = 'rms',
  PEAK_X = 'peak_x',
  PEAK_Y = 'peak_y',
  PEAK_Z = 'peak_z',
  AREA_X = 'area_x',
  AREA_Y = 'area_y',
  AREA_Z = 'area_z',
  RMS_X = 'rms_x',
  RMS_Y = 'rms_y',
  RMS_Z = 'rms_z',
}

export enum FeatureNaming {
  PEAK = 'peak_gs',
  AREA = 'area_gs_per_sec',
  RMS = 'rms_gs',
}

export interface EachAxis<T> {
  x: T;
  y: T;
  z: T;
}

export interface ShocksPerMonth {
  month: string;
  count: number;
}
export interface AssetStats {
  first_shock_ts: Date;
  last_shock_ts: Date;
  count: number;
  shocks_per_month: ShocksPerMonth[];
}

export interface PaginatedShocks {
  items: Shock[];
  page: number;
  size: number;
  count: number;
}

export interface Shock {
  id: string;
  asset_id: string;
  device_id: string;
  ts: Date;
  tenant: string;
  location: string | null;
  geohash: string | null;
  address: Address | null;
  sample_rate_hz: number;
  curve_gs: EachAxis<number[]>;
  area_gs_per_sec: EachAxis<number>;
  peak_gs: EachAxis<number>;
  rms_gs: EachAxis<number>;
  is_noise: boolean;
  metadata: ShockMetadata | null;
  asset_metadata: Record<string, unknown> | null;
  discovered_ts: Date | null;
  measured_ts: Date | null;
  speed: number | null;
  sl_speed: number | null;
  motion_state: string | null;
  has_feedback: boolean;
}

export interface Address {
  road: string;
  village: string;
  stateDistrict: string;
  state: string;
  postcode: string;
  country: string;
  countryCode: string;
  displayName: string;
}

export interface ShockMetadata {
  type?: string;
  acceleration?: Acceleration;
  gpsLocation?: GpsLocation;
  startstop?: boolean;
  route?: string;
}

interface Acceleration {
  z: number;
  x: number;
  y: number;
  detectCount: number;
}

interface GpsLocation {
  heading: number;
  hdop: number;
  satelliteCount: number;
  elevation: number;
  speed: number;
  snr: number;
}

export interface HistogramFeature
  extends Omit<HistogramAxisStats, 'histogram'> {
  x: HistogramSection[];
  y: HistogramSection[];
  z: HistogramSection[];
}

export interface HistogramAxisStats {
  axis: Axis;
  feature: Feature;
  bin_size: number;
  histogram: HistogramSection[];
}

export interface HistogramSection {
  bin_floor: number;
  frequency: number;
}

export enum DamageType {
  Bearings = 'Bearings',
  Buffers = 'Buffers',
  Wheelset = 'Wheelset',
}

export enum Classification {
  Shunting = 'Shunting',
  Derailment = 'Derailment',
  LoadDrop = 'LoadDrop',
}

export interface Feedback {
  category?: Classification;
  ignore: boolean;
  damage?: DamageType[];
  description?: string;
}

export interface FeedbackDto {
  revision: number;
  created_at: Date;
  created_by: string;
  feedback: Feedback;
  shock_id: string;
}

export interface FeedbackRevisionsDto {
  revisions: FeedbackDto[];
}

export enum ShockTableOrderBy {
  TIME = 'time',
  PEAK = 'peak',
  AREA = 'area',
  RMS = 'rms',
  PEAK_X = 'peak_x',
  PEAK_Y = 'peak_y',
  PEAK_Z = 'peak_z',
  AREA_X = 'area_x',
  AREA_Y = 'area_y',
  AREA_Z = 'area_z',
  RMS_X = 'rms_x',
  RMS_Y = 'rms_y',
  RMS_Z = 'rms_z',
}

export enum ShockTableOrderDir {
  ASC = 'asc',
  DESC = 'desc',
}

export interface TablePageInfo {
  order_by?: ShockTableOrderBy;
  order_dir?: ShockTableOrderDir;
  page: number;
  size: number;
}

export interface ShockIndexDto {
  days: ShocksPerDayDto[];
}

export interface ShocksPerDayDto {
  day: string;
  count: number;
}

export interface ClustersDto {
  clusters: Cluster[];
}

export interface Cluster {
  geohash: string;
  centroid: string;
  count: string;
}

export interface MapMove {
  zoom: number;
  bounds: LatLngBounds;
}

export interface ClusterInformation {
  bbox: GeographicBoundingBox;
  popup: Popup;
}
