import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AssetsQuery } from '../../../../../@core/state/assets.state';

@Component({
  selector: 'ngx-wagon-column',
  template: `
    <span *ngIf="wagon$ | async as wagon; else noWagon">{{ wagon }}</span>
    <ng-template #noWagon>
      <span style="color: #8f9bb3; weight: 400">NO WAGON NAME</span>
    </ng-template>
  `,
})
export class WagonColumnComponent {
  private assetId$ = new BehaviorSubject<string>('');

  wagon$ = this.assetId$.pipe(
    switchMap((assetId) => this.assetsQuery.selectEntity(assetId)),
    map((asset) => asset?.metadata?.['Wagon']?.['Wagon number']),
  );

  @Input() set assetId(assetId: string) {
    this.assetId$.next(assetId);
  }

  constructor(private assetsQuery: AssetsQuery) {}
}
