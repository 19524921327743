<div>
  <input
    class="btn btn-light datepicker"
    [nbPopover]="shortcutRef"
    nbPopoverPlacement="bottom"
    [value]="display"
  />
  <input #rangePickerInput class="hidden-datepicker"  [nbDatepicker]="rangepicker" [(ngModel)]="ngModel" />
  <nb-rangepicker
    #rangepicker
    (rangeChange)="timeRangeChange($event)"
    [filter]="enabledDateRange"
  ></nb-rangepicker>
</div>
<ng-template #shortcutRef>
  <div class="d-flex p-2">
    <button
      nbButton
      size="small"
      class="m-1"
      [status]="selected === 'today' ? 'primary' : 'basic'"
      (click)="setRange('today')"
    >
      today
    </button>
    <button
      nbButton
      size="small"
      class="m-1"
      [status]="selected === 'week' ? 'primary' : 'basic'"
      (click)="setRange('week')"
    >
      week
    </button>
    <button
      nbButton
      size="small"
      class="m-1"
      [status]="selected === 'month' ? 'primary' : 'basic'"
      (click)="setRange('month')"
    >
      month
    </button>
    <button
      nbButton
      size="small"
      class="m-1"
      [status]="selected === 'year' ? 'primary' : 'basic'"
      (click)="setRange('year')"
    >
      year
    </button>
    <button
      nbButton
      size="small"
      class="m-1"
      [status]="selected === 'custom' ? 'primary' : 'basic'"
      (click)="setRange('custom');"
    >
      custom
    </button>
  </div>
</ng-template>
