/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @angular-eslint/directive-selector */
import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * This is a helper directive to enable querying `TemplateRef`s with `@ViewChildren(TemplateRefId)`
 * intead of having to id each template
 *
 * @example
 * ```
 * // without `TemplateRefId`
 *
 * -@ViewChild('someId0') someId0Tpl: TemplateRef<any>;
 *
 * -@ViewChild('someId1') someId1Tpl: TemplateRef<any>;
 *
 * // with `TemplateRefId`
 *
 * +_templates: Map<string, TemplateRef<any>>;
 *
 * +@ViewChildren(TemplateRefId) set tpls(templates: TemplateRefId[]) {
 * +  for (const tpl of templates) {
 * +    templates[tpl.templateId] = tpl.templateRef;
 * +  }
 * +}
 * ```
 */
@Directive({
  selector: 'ng-template[templateId]',
})
export class TemplateRefId<ID = string, C = unknown> {
  constructor(public templateRef: TemplateRef<C>) {}
  @Input() templateId: ID;
}
