import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { TableColumn } from '../shocks-table.interfaces';

@Component({
  selector: 'ngx-checkboxes-list-modal',
  templateUrl: './shocks-table-config-modal.component.html',
  styleUrls: ['./shocks-table-config-modal.component.scss'],
})
export class ShocksTableConfigModalComponent {
  allColumns: TableColumn[];
  error = false;

  constructor(public ref: NbDialogRef<ShocksTableConfigModalComponent>) {}

  save(): void {
    if (!this.allColumns.some((config) => config.show)) {
      this.error = true;
      return;
    }
    this.ref.close(this.allColumns);
  }
}
