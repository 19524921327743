import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FeatureFilter, Filters, Interval } from '../model/filters.model';
import { Observable } from 'rxjs';
import {
  AssetStats,
  Axis,
  ClustersDto,
  Feature,
  HistogramAxisStats,
  PaginatedShocks,
  Shock,
  ShockIndexDto,
  TablePageInfo,
} from '../model/shockpit.interfaces';
import { environment } from '../../../environments/environment';
import { isNil, snakeCase, isDate, isPrimitive } from '../utils';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ShocksDataService {
  constructor(private http: HttpClient) {}

  getShocksByTablePage(
    filters: Filters,
    pageInfo: TablePageInfo = { page: 1, size: 1000 },
  ): Observable<PaginatedShocks> {
    const serializedFilter = this.serialiseParams({
      ...filters,
      features: this.parseFeatureFilterToString(filters.features),
    });

    return this.http
      .get<PaginatedShocks>(`${environment.snorlaxApi}/shocks/search`, {
        params: {
          ...serializedFilter,
          ...this.serialiseParams(
            pageInfo as unknown as Record<string, unknown>,
          ),
        },
      })
      .pipe(
        map((res) => ({
          ...res,
          items: res.items.map((s) => this.mapShock(s)),
        })),
      );
  }

  getHistogramByFeature(
    feature: Feature,
    axis: Axis,
    filters: Filters,
  ): Observable<HistogramAxisStats> {
    const params = this.serialiseParams({
      ...filters,
      features: this.parseFeatureFilterToString(filters.features),
    });

    return this.http.get<HistogramAxisStats>(
      `${environment.snorlaxApi}/shocks/histogram/${feature}/${axis}`,
      { params },
    );
  }

  getBookmarks(): Observable<{ bookmarks: string[] }> {
    return this.http.get<{ bookmarks: string[] }>(
      `${environment.snorlaxApi}/shock-metadata/bookmarks`,
    );
  }

  putBookmark({ id }: Shock): Observable<void> {
    return this.http.put<void>(
      `${environment.snorlaxApi}/shock-metadata/bookmarks/${id}`,
      {},
    );
  }

  deleteBookmark({ id }: Shock): Observable<void> {
    return this.http.delete<void>(
      `${environment.snorlaxApi}/shock-metadata/bookmarks/${id}`,
      {},
    );
  }

  getVisited(): Observable<{ visited: string[] }> {
    return this.http.get<{ visited: string[] }>(
      `${environment.snorlaxApi}/shock-metadata/visited`,
    );
  }

  putVisited({ id }: Shock): Observable<void> {
    return this.http.put<void>(
      `${environment.snorlaxApi}/shock-metadata/visit/${id}`,
      {},
    );
  }

  getAssetStats(assetId: string): Observable<AssetStats> {
    return this.http
      .get<AssetStats>(
        `${environment.snorlaxApi}/shocks/asset-statistics/${assetId}`,
      )
      .pipe(
        map((response) => ({
          ...response,
          first_shock_ts: new Date(response.first_shock_ts),
          last_shock_ts: new Date(response.last_shock_ts),
        })),
      );
  }

  getExportCsv(filters: Filters): Observable<Blob> {
    const serializedFilter = this.serialiseParams({
      ...filters,
      features: this.parseFeatureFilterToString(filters.features),
    });

    return this.http.get(`${environment.snorlaxApi}/shocks/export`, {
      params: {
        ...serializedFilter,
      },
      responseType: 'blob',
    });
  }

  getOneShock(id: string): Observable<Shock> {
    return this.http.get<Shock>(`${environment.snorlaxApi}/shocks/def/${id}`);
  }

  getShockIndex({ start, end }: Interval): Observable<ShockIndexDto> {
    return this.http.get<ShockIndexDto>(
      `${environment.snorlaxApi}/shocks/shock-index`,
      {
        params: {
          start: start.toISOString(),
          end: end.toISOString(),
        },
      },
    );
  }

  getClusters(zoom: number, filters: Filters): Observable<ClustersDto> {
    const serializedFilter = this.serialiseParams({
      ...filters,
      features: this.parseFeatureFilterToString(filters.features),
    });
    return this.http.get<ClustersDto>(
      `${environment.snorlaxApi}/shocks/clusters/${zoom}`,
      {
        params: {
          ...serializedFilter,
        },
      },
    );
  }

  getBookmarkedShocks(): Observable<Shock[]> {
    return this.http.get<Shock[]>(
      `${environment.snorlaxApi}/shocks/bookmarked`,
    );
  }

  private parseFeatureFilterToString(filters: FeatureFilter): string | null {
    if (!filters || filters.featuresList.length === 0) {
      return null;
    }

    const clauses = filters.featuresList.map(
      (f) => `${f.feature}|${f.axis}|${f.operator}|${f.value}`,
    );
    return `AND(${clauses.join(',')})`;
  }

  private serialiseParams(
    filters: Record<string, unknown>,
  ): Record<string, string> {
    return Object.entries(filters).reduce((acc, [k, v]) => {
      if (isNil(v)) {
        return acc;
      }

      if (isPrimitive(v)) {
        acc[snakeCase(k)] = v;
        return acc;
      }

      if (isDate(v)) {
        acc[snakeCase(k)] = v.toISOString();
        return acc;
      }

      throw new Error(
        `Trying to serialise unsupported query parameter type ${typeof v}`,
      );
    }, {});
  }

  /**
   * Used to map the json representation of a shock to a plain js object.
   */
  private mapShock(s: Shock): Shock {
    return {
      ...s,
      ts: new Date(s.ts),
      measured_ts: s.measured_ts && new Date(s.measured_ts),
      discovered_ts: s.measured_ts && new Date(s.discovered_ts),
    };
  }
}
