<nb-icon [icon]="show ? 'arrow-forward-outline' : 'arrow-back-outline'"
         pack="eva"
         class="show-hide-toggle"
         (click)="toggle()">
</nb-icon>
<div class="slide-out-container"
     [class.expanded]="show"
     [class.collapsed]="!show">
  <div class="content-wrapper">
    <ng-content></ng-content>
  </div>
</div>
