import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map, catchError, tap, filter } from 'rxjs/operators';
import {
  HttpBackend,
  HttpEventType,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenProvider {
  private sessionKey: string;
  private currentJwt: string | null;

  constructor(private readonly backend: HttpBackend) {}

  getJwt(session: string | null): Observable<string | null> {
    // when sesson is null, return null
    if (!session) return of(null);
    // when we already fetched an id_token
    if (session === this.sessionKey) {
      return of(this.currentJwt);
    }
    return this.fetchToken(session).pipe(
      tap((token) => {
        this.sessionKey = session;
        this.currentJwt = token;
      }),
    );
  }

  /**
   * We use `HttpBackend` to circunvent the AuthInterceptor and avoid a deadlock
   * @param session
   * @returns
   */
  private fetchToken(session: string): Observable<string | null> {
    const baseUrl = environment.accessTokensApi;
    const body = {
      grant_type: 'cognito_token',
      token: session,
    };
    const req = new HttpRequest('POST', `${baseUrl}/oidc/token`, body, {
      withCredentials: false,
      responseType: 'json',
    });
    return this.backend.handle(req).pipe(
      filter((ev) => ev.type === HttpEventType.Response),
      map((ev: HttpResponse<{ id_token: string }>) => ev.body?.['id_token']),
      catchError((error) => {
        console.log(error);
        return of(null);
      }),
    );
  }
}
