import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthQuery } from '../state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authQuery: AuthQuery) {}

  canActivate(): boolean {
    return this.authQuery.isLoggedIn();
  }
}
