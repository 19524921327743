<nb-card size="medium">
  <nb-card-header>
    Tenants
    <button nbButton ghost status="primary" (click)="select(null)">
      RESET
    </button>
  </nb-card-header>
  <nb-list>
    <nb-list-item (click)="select(t.tenant)" *ngFor="let t of tenants">
      <span>{{ t.tenant }}</span>
      <span>{{ t.count | number }}</span>
    </nb-list-item>
  </nb-list>
</nb-card>
