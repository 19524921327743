import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  Classification,
  DamageType,
  Feedback,
  FeedbackRevisionsDto,
  Shock,
} from '../../../../@core/model/shockpit.interfaces';
import { ShocksDataService } from '../../../../@core/services/shocks-data.service';
import { finalize } from 'rxjs/operators';
import { FeedbackService } from '../../../../@core/services/feedback.service';

type FeedbackForm = Pick<Feedback, 'category' | 'damage' | 'description'> & {
  interesting: boolean;
};

@Component({
  selector: 'ngx-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnInit {
  form: FormGroup;

  loading: boolean;

  err: any;

  thankYou: boolean;

  moreDetails = false;

  feedbackRevisions: FeedbackRevisionsDto;

  @Input() shock: Shock;

  Classification = Classification;
  DamageType = DamageType;

  constructor(
    private fb: FormBuilder,
    private feedbackService: FeedbackService,
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.loading = true;
    this.feedbackService
      .getFeedbackHistory(this.shock)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(
        (feedbackRevisionsDto) => {
          this.feedbackRevisions = feedbackRevisionsDto;
          const feedback = feedbackRevisionsDto.revisions?.[0]?.feedback;
          this.form = this.fb.group({
            interesting: this.fb.control(
              feedback?.ignore === undefined ? true : !feedback.ignore,
            ),
            category: this.fb.control(feedback?.category),
            damage: this.fb.control(feedback?.damage),
            description: this.fb.control(feedback?.description),
          });
          this.onFormChanged();
        },
        (err) => {
          this.handleError(err);
        },
      );
  }

  onFormChanged(): void {
    const formValue = this.form.value as FeedbackForm;
    Object.keys(this.form.controls).forEach((control) => {
      if (control !== 'interesting') {
        if (formValue.interesting) {
          this.form.controls[control].enable();
        } else {
          this.form.controls[control].disable();
        }
      }
    });
  }

  save(): void {
    if (this.form.invalid) return;
    this.loading = true;
    const { interesting, ...formValues } = this.form.value as FeedbackForm;
    const body = {
      ...formValues,
      ignore: !interesting,
    };
    this.feedbackService
      .saveFeedback(this.shock, body)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe(
        () => {
          this.sayThankYou();
        },
        (err) => {
          this.handleError(err);
        },
      );
  }

  private sayThankYou() {
    this.thankYou = true;
    setTimeout(() => {
      this.thankYou = false;
      this.initForm();
    }, 2000);
  }

  private handleError(err: any): void {
    if (err instanceof HttpErrorResponse && err.status === 404) {
      this.form = this.fb.group({
        interesting: this.fb.control(true),
        category: this.fb.control(null),
        damage: this.fb.control(null),
        description: this.fb.control(null),
      });
      this.onFormChanged();
    } else if (err instanceof HttpErrorResponse && err.status === 403) {
      this.err = 'Your user does not have access to this resource';
    } else {
      this.err = err.message || err;
    }
  }

  toggleMoreDetails(): void {
    this.moreDetails = !this.moreDetails;
  }
}
