export const ChannelPrefix = 'nexxiot.';
export const ConnectedMessageId = `${ChannelPrefix}connected`;

interface Callback<T = unknown> {
  (err?: T, data?: T): void;
}
export interface Channel {
  getAll: (callback: Callback) => void;
  isLoggedIn: (callback: Callback<boolean>) => void;
  getToken: (callback: Callback<string>) => void;
  close: () => void;
}

function getId(data): string | undefined {
  if (data && data.id && ~data.id.indexOf(ChannelPrefix)) {
    return data.id;
  }
}

function createId(): string {
  return `${ChannelPrefix}${Date.now()}.${Math.floor(Math.random() * 100)}`;
}

export function createGuest(source: string, parent?: HTMLElement): Channel {
  parent = parent || document.body;

  let contentWindow;
  let callbacks = {};
  const sessionRequests: [string, string, string, Callback][] = [];
  let connected = false;
  let closed = true;
  let connectedTimeout;
  let isLoaded = false;

  const iframe = document.createElement('iframe');
  iframe.src = source;
  iframe.width = '0';
  iframe.height = '0';
  iframe.style.display = 'none';
  iframe.onload = () => {
    isLoaded = true;
  };

  function openStorage() {
    parent.appendChild(iframe);
    contentWindow = iframe.contentWindow;
    closed = false;

    window.addEventListener('message', handleMessage);

    checkConnected();
  }

  openStorage();

  function handleMessage(event) {
    const response = event.data;
    const sessionAccessId = getId(response);

    if (sessionAccessId === ConnectedMessageId) {
      console.debug('Connected to host!');
      connected = true;
      return;
    }

    if (response.connectError) {
      Object.keys(callbacks).forEach((key) => callbacks[key](response.error));
      callbacks = {};
      return;
    }

    const callback = callbacks[sessionAccessId];

    if (sessionAccessId && callback) {
      console.debug(
        `Host responded with: ${sessionAccessId}: ${JSON.stringify(
          response.data,
        )}`,
      );
      callback(response.error, response.data);
    }
  }

  function close() {
    clearTimeout(connectedTimeout);
    window.removeEventListener('message', handleMessage);
    iframe.parentNode.removeChild(iframe);
    connected = false;
    closed = true;
    console.debug('Connection closed!');
  }

  function message(
    method: string,
    key?: string,
    value?: string,
    callback?: Callback,
  ) {
    if (closed) {
      openStorage();
    }

    if (!connected && method !== 'connect') {
      sessionRequests.push([method, key, value, callback]);
    }

    const id = createId();

    if (callbacks && typeof callback === 'function') {
      callbacks[id] = callback;
    }

    if (isLoaded) {
      console.debug(`Sending message ${id}...`);
      contentWindow.postMessage(
        {
          method,
          key,
          value,
          id,
        },
        source,
      );
    }
  }

  function getAll(callback) {
    if (!callback) {
      throw new Error('callback required for get');
    }

    message('getAll', null, null, callback);
  }

  function isLoggedIn(callback) {
    if (!callback) {
      throw new Error('callback required for isLoggedIn');
    }

    message('isLoggedIn', null, null, callback);
  }

  function getToken(callback) {
    if (!callback) {
      throw new Error('callback required for get');
    }

    message('getToken', null, null, callback);
  }

  function checkConnected() {
    if (connected) {
      clearTimeout(connectedTimeout);
      while (sessionRequests.length) {
        message(...sessionRequests.pop());
      }

      return;
    }

    message('connect');

    connectedTimeout = setTimeout(checkConnected, 125);
  }

  return {
    getAll,
    isLoggedIn,
    getToken,
    close,
  };
}
