import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  Feedback,
  FeedbackRevisionsDto,
  PaginatedShocks,
  Shock,
  TablePageInfo,
} from '../model/shockpit.interfaces';
import { environment } from '../../../environments/environment';
import { Interval } from '../model/filters.model';
import { tap, map } from 'rxjs/operators';
import { ShocksDataService } from './shocks-data.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  classifiedShocks$ = new Subject<PaginatedShocks>();
  unclassifiedShocks$ = new Subject<PaginatedShocks>();

  shocks$ = combineLatest([
    this.classifiedShocks$,
    this.unclassifiedShocks$,
  ]).pipe(map(([classified, unclassified]) => ({ classified, unclassified })));

  constructor(
    private http: HttpClient,
    private shocksDataService: ShocksDataService,
  ) {}

  loadClassifiedShocks(
    { start, end }: Interval,
    pageInfo: TablePageInfo,
  ): Observable<PaginatedShocks> {
    return this.shocksDataService
      .getShocksByTablePage({ start, end, feedback: 'ANY' }, pageInfo)
      .pipe(tap((shocks) => this.classifiedShocks$.next(shocks)));
  }

  loadUnclassifiedShocks(
    { start, end }: Interval,
    pageInfo: TablePageInfo,
  ): Observable<PaginatedShocks> {
    return this.shocksDataService
      .getShocksByTablePage({ start, end, feedback: 'NO' }, pageInfo)
      .pipe(tap((shocks) => this.unclassifiedShocks$.next(shocks)));
  }

  getFeedbackHistory({ id }: Shock): Observable<FeedbackRevisionsDto> {
    return this.http
      .get<FeedbackRevisionsDto>(
        `${environment.snorlaxApi}/shock-feedback/${id}/revisions`,
      )
      .pipe(
        map((response) => ({
          revisions: response.revisions.map((r) => ({
            ...r,
            created_at: new Date(r.created_at),
          })),
        })),
      );
  }

  saveFeedback({ id }: Shock, body: Feedback): Observable<void> {
    return this.http.put<void>(
      `${environment.snorlaxApi}/shock-feedback/${id}`,
      body,
    );
  }
}
