import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ShockTableState, TableColumn } from './shocks-table.interfaces';
import { columns } from './shock-table-columns';
import {
  StorageService,
  STORAGE_KEYS,
} from '../../../@core/utils/storage.service';

const storagekey = STORAGE_KEYS.ShocksTableState;

@Injectable({
  providedIn: 'root',
})
export class ShocksTableService {
  private columns = new BehaviorSubject<TableColumn[]>(this.defaultColumns());

  /**
   * Columns to be displayed
   */
  columns$ = this.columns.pipe(map((cols) => cols.filter((col) => col.show)));

  constructor(private storage: StorageService) {}

  /**
   * all available columns
   */
  allColumns(): TableColumn[] {
    return this.columns.getValue();
  }

  /**
   * Changes which columns to show
   */
  changeDisplayedColumns(cols: TableColumn[]): void {
    if (!cols) return;
    this.columns.next(cols);
    const displayedColumnNames = cols
      .filter((col) => col.show)
      .map((col) => col.name);
    this.storage.set<ShockTableState>(storagekey, { displayedColumnNames });
  }

  /**
   * Merges the columns defined in code with the state saved on local storage
   * to keep the dislayed columns the same after page reloads
   */
  private defaultColumns(): TableColumn[] {
    const shockTableState = this.storage.get<ShockTableState>(storagekey);
    if (shockTableState?.displayedColumnNames) {
      return columns.map((col) => ({
        ...col,
        show: shockTableState.displayedColumnNames.includes(col.name),
      }));
    }
    return columns;
  }
}
