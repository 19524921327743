import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ShockPerTenant, TenantsService } from './tenants-dialog.service';

@Component({
  selector: 'ngx-tenants-dialog',
  templateUrl: './tenants-dialog.component.html',
  styleUrls: ['./tenants-dialog.component.scss'],
})
export class TenantsDialogComponent implements OnInit {
  tenants: ShockPerTenant = [];
  constructor(
    private tenantsService: TenantsService,
    private dialog: NbDialogRef<TenantsDialogComponent>,
  ) {}

  ngOnInit(): void {
    this.loadTenants();
  }

  async loadTenants(): Promise<void> {
    this.tenants = await this.tenantsService.getTenants();
  }

  select(tenant: string): void {
    this.dialog.close(tenant);
  }
}
