import { Component, Input, OnInit } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';
import { map } from 'rxjs/operators';
import { ShocksService } from '../../../@core/services/shocks.service';
import { shockId } from '../../../@core/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ShockTableItem } from '../shocks-table/shocks-table.interfaces';

@UntilDestroy()
@Component({
  selector: 'ngx-shock-curve-dialog',
  templateUrl: './shock-curve-dialog.component.html',
  styleUrls: ['./shock-curve-dialog.component.scss'],
})
export class ShockCurveDialogComponent implements OnInit {
  @Input() shock: ShockTableItem;

  bookmarked$ = this.shockService.getBookmarks$().pipe(
    untilDestroyed(this),
    map((bookmarks) => bookmarks.includes(shockId(this.shock))),
  );

  constructor(
    private windowRef: NbWindowRef,
    private shockService: ShocksService,
  ) {}

  ngOnInit(): void {
    // bugfix ExpressionChangedAfterItHasBeenCheckedError
    setTimeout(() => {
      this.shockService.addVisited(this.shock);
    });
  }

  bookmark(): void {
    this.shockService.toggleBookmarks(this.shock).subscribe();
  }

  close(): void {
    this.windowRef.close();
  }
}
