import { Injectable } from '@angular/core';
import { ShocksDataService } from './shocks-data.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  PaginatedShocks,
  Shock,
  ShockIndexDto,
  ShockTableOrderBy,
  ShockTableOrderDir,
  TablePageInfo,
} from '../model/shockpit.interfaces';
import { Filters, Interval } from '../model/filters.model';
import { finalize, map, tap } from 'rxjs/operators';
import { endOfDay, startOfMonth } from 'date-fns';
import { shockId } from '../utils';
import { NbAccessChecker } from '@nebular/security';

export const DEFAULT_PAGINATED_SHOCKS: PaginatedShocks = {
  items: [],
  page: 0,
  size: 0,
  count: 0,
};

export const DEFAULT_PAGE_INFO: TablePageInfo = Object.freeze({
  order_by: ShockTableOrderBy.TIME,
  order_dir: ShockTableOrderDir.DESC,
  page: 1,
  size: 10,
});

@Injectable({
  providedIn: 'root',
})
export class ShocksService {
  private visited$ = new BehaviorSubject<string[]>([]);
  private bookmarked$ = new BehaviorSubject<string[]>([]);
  private paginatedShocks$ = new BehaviorSubject<PaginatedShocks>(
    DEFAULT_PAGINATED_SHOCKS,
  );
  private filters$ = new BehaviorSubject<Filters>({
    start: startOfMonth(new Date()),
    end: endOfDay(new Date()),
    noise: this.checker.isGranted('read', 'noise') ? 'SHOW' : 'HIDE',
  });

  private isLoadingSource = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSource.asObservable();

  constructor(
    private dataService: ShocksDataService,
    private checker: NbAccessChecker,
  ) {}

  getFilter(): Filters {
    return this.filters$.getValue();
  }

  getFilter$(): Observable<Filters> {
    return this.filters$.asObservable();
  }

  setFilter(filter: Filters): void {
    this.filters$.next(filter);
  }

  patchFilter(value: Partial<Filters>): void {
    this.filters$.next({
      ...this.filters$.getValue(),
      ...value,
    });
  }

  getBookmarks$(): Observable<string[]> {
    return this.bookmarked$.asObservable();
  }

  loadBookmarks(): Observable<{ bookmarks: string[] }> {
    return this.dataService
      .getBookmarks()
      .pipe(tap((data) => this.bookmarked$.next(data.bookmarks)));
  }

  toggleBookmarks(shock: Shock): Observable<void> {
    const bookmarkList = this.bookmarked$.getValue();
    const id = shockId(shock);
    let actionFunc;
    let observable: Observable<void>;

    if (bookmarkList.includes(id)) {
      observable = this.dataService.deleteBookmark(shock);
      actionFunc = () => {
        const indexToDelete = bookmarkList.findIndex((bm) => bm === id);
        bookmarkList.splice(indexToDelete, 1);
      };
    } else {
      observable = this.dataService.putBookmark(shock);
      actionFunc = () => {
        bookmarkList.push(id);
      };
    }

    return observable.pipe(
      finalize(() => {
        this.bookmarked$.next([...bookmarkList]);
      }),
      tap(actionFunc),
    );
  }

  getVisited$(): Observable<string[]> {
    return this.visited$.asObservable();
  }

  loadVisited(): Observable<{ visited: string[] }> {
    return this.dataService
      .getVisited()
      .pipe(tap((data) => this.visited$.next(data.visited)));
  }

  addVisited(shock: Shock): void {
    const visited = this.visited$.getValue();
    const id = shockId(shock);
    if (!visited.includes(id)) {
      this.dataService.putVisited(shock).subscribe(() => {
        this.visited$.next([...visited, id]);
      });
    }
  }

  getShocks$(): Observable<Shock[]> {
    return this.paginatedShocks$
      .asObservable()
      .pipe(map((paginatedShocks) => paginatedShocks.items));
  }

  getPaginatedShocks$(): Observable<PaginatedShocks> {
    return this.paginatedShocks$.asObservable();
  }

  loadShocksByTablePage(
    filters: Filters,
    pageInfo: TablePageInfo = DEFAULT_PAGE_INFO,
  ): Observable<PaginatedShocks> {
    this.isLoadingSource.next(true);
    return this.dataService.getShocksByTablePage(filters, pageInfo).pipe(
      tap((res) => this.paginatedShocks$.next({ ...res })),
      finalize(() => this.isLoadingSource.next(false)),
    );
  }

  getShockIndex({ start, end }: Interval): Observable<ShockIndexDto> {
    return this.dataService.getShockIndex({ start, end });
  }
}
