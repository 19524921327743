import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export interface Asset {
  asset_id: string;
  device_id?: string;
  asset_name: string;
  location?: { lat: number; lon: number };
  motion: {
    status: 'inactive' | 'idle' | 'moving' | 'stationary';
    since_ts: number;
  };
  asset_type: string;
  tenant: string;
  formatted_address: string;
  last_message_ts?: number;
  metadata: Record<string, unknown>;
}

const fields =
  'asset_id,device_id,asset_name,location,motion,asset_type,tenant,formatted_address,last_message_ts,metadata';

@Injectable({
  providedIn: 'root',
})
export class AssetDataService {
  private baseUrl = environment.nexiotApi;

  constructor(private http: HttpClient) {}

  findAll(tenant: string): Observable<Asset[]> {
    return this.http.get<Asset[]>(`${this.baseUrl}/v1/_es/nodes/assets`, {
      params: {
        fields,
        filters: `tenant:${tenant}`,
      },
    });
  }

  findOne(assetId: string): Observable<Asset | null> {
    return this.http
      .get<Asset[]>(`${this.baseUrl}/v1/_es/nodes/assets`, {
        params: {
          fields,
          filters: `asset_id:${assetId}`,
        },
      })
      .pipe(map((response) => response?.[0] || null));
  }

  search(nameOrId: string): Observable<Asset[]> {
    function getSearchParams(term?: string): Record<string, string> {
      if (!term) return {};
      const normalised = term.replace(/\W/g, '').toLowerCase();
      let search = `asset_name:${term},asset_id:${term},device_id:${term}`;
      if (normalised) {
        search += `,asset_name.normalized:${normalised}`;
      }
      return { search };
    }

    return this.http.get<Asset[]>(`${this.baseUrl}/v1/_es/nodes/assets`, {
      params: {
        fields: 'asset_id,asset_name',
        ...getSearchParams(nameOrId),
      },
    });
  }

  async intallationPicture(assetId: string): Promise<string> {
    const response = await this.http
      .get(
        `${this.baseUrl}/v1/assets/documents/${assetId}/pairing/Montage.jpg`,
        {
          responseType: 'blob',
        },
      )
      .toPromise();

    const blob = new Blob([response], { type: 'image/jpg' });
    const base64 = await this.blobToBase64(blob);
    return base64;
  }

  private blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onerror = reject;
      fr.onload = function () {
        resolve(fr.result as string);
      };
      fr.readAsDataURL(blob);
    });
  }
}
