import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { circle, latLng, latLngBounds, polygon, rectangle } from 'leaflet';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthQuery } from '../../auth/state/auth.state';

export interface Circle {
  type: 'Circle';
  radius: number; // in KM
  center: { lat: number; lon: number };
}

export interface Rectangle {
  type: 'Rectangle';
  topLeft: { lat: number; lon: number };
  bottomRight: { lat: number; lon: number };
}

export interface Polygon {
  type: 'Polygon';
  points: { lat: number; lon: number }[];
}

export type Geometry = Circle | Rectangle | Polygon;

export interface Geofence {
  geofence_id: string;
  name: string;
  geometry: Geometry;
}

@Injectable({
  providedIn: 'root',
})
export class GeofenceDataService {
  private baseUrl = environment.nexiotApi;

  constructor(private http: HttpClient, private authQuery: AuthQuery) {}

  search(search: string): Observable<{ items: Geofence[]; total: number }> {
    return this.authQuery.tenant$.pipe(
      take(1),
      switchMap((tenant) =>
        this.http.get<{ items: Geofence[]; total: number }>(
          `${this.baseUrl}/v1/_es/geofences`,
          {
            params: {
              fields: 'geofence_id,name,geometry,tenant',
              search: search?.length ? `name:${search},id:${search}` : '',
              size: '60',
              filters: `tenant:${tenant}`,
              global:
                this.authQuery.getUser().tenant === 'nexiot' ? 'true' : 'false',
            },
          },
        ),
      ),
    );
  }

  getPolygon(geometry: Geometry): L.Circle | L.Rectangle | L.Polygon {
    const pathOptions = {
      color: 'steelblue',
      weight: 1,
      fillOpacity: 0.1,
    };
    switch (geometry.type) {
      case 'Circle':
        return circle(latLng(geometry.center.lat, geometry.center.lon), {
          radius: geometry.radius * 1000,
          ...pathOptions,
        });
      case 'Rectangle':
        const { topLeft, bottomRight } = geometry;
        // southWest x northEast
        return rectangle(
          latLngBounds(
            [bottomRight.lat, topLeft.lon],
            [topLeft.lat, bottomRight.lon],
          ),
          pathOptions,
        );
      case 'Polygon':
        return polygon(
          geometry.points.map(({ lat, lon }) => [lat, lon]),
          pathOptions,
        );
    }
  }
}
