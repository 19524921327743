import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbAlertModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbButtonGroupModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbPopoverModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbThemeModule,
  NbToggleModule,
  NbTooltipModule,
  NbUserModule,
  NbWindowModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import {
  FooterComponent,
  GeofenceDialogComponent,
  HeaderComponent,
  LoadingComponent,
  RangePickerComponent,
  SearchControlComponent,
  ShockCurveDialogComponent,
  ShockMapComponent,
  SlideOutComponent,
  SubnavComponent,
  UserBadgeComponent,
} from './components';
import {
  AssetNamePipe,
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  ShockIndexPipe,
  TimingPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';

import { NEXXIOT_THEME } from './styles/theme.nexxiot';
import { DEFAULT_THEME } from './styles/theme.default';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { NgxEchartsModule } from 'ngx-echarts';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletD3Module } from '@asymmetrik/ngx-leaflet-d3';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {
  LocationPipe,
  ShocksTableComponent,
} from './components/shocks-table/shocks-table.component';
import { AxisValuesComponent } from './components/axis-values/axis-values.component';
import { ShockCurveGraphComponent } from './components/shock-curve-dialog/shock-curve-graph/shock-curve-graph.component';
import { FeedbackFormComponent } from './components/shock-curve-dialog/feedback-form/feedback-form.component';
import { ShocksTableConfigModalComponent } from './components/shocks-table/shocks-table-config-modal/shocks-table-config-modal.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { WagonColumnComponent } from './components/shocks-table/columns/wagon-column/wagon-column.component';
import { TemplateRefId } from './directives/template-ref-id.directive';
import { PredictionNamePipe } from './pipes/prediction-name.pipe';
import { TenantsDialogComponent } from './components/tenants-dialog/tenants-dialog.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbButtonGroupModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbDatepickerModule,
  NbDateFnsDateModule,
  NbDialogModule,
  NbCardModule,
  NbPopoverModule,
  NbListModule,
  NbTabsetModule,
  NbAutocompleteModule,
  NbToggleModule,
  NbWindowModule,
  NbInputModule,
  NbAlertModule,
  NbTooltipModule,
  NbCheckboxModule,
];
const OTHER_MODULES = [
  NgxEchartsModule,
  LeafletModule,
  LeafletD3Module,
  NgxSliderModule,
  NgxDatatableModule,
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  SubnavComponent,
  RangePickerComponent,
  LoadingComponent,
  SlideOutComponent,
  ShockCurveDialogComponent,
  ShockMapComponent,
  SearchControlComponent,
  GeofenceDialogComponent,
  ShocksTableComponent,
  AxisValuesComponent,
  ShockCurveGraphComponent,
  FeedbackFormComponent,
  UserBadgeComponent,
  ShocksTableConfigModalComponent,
  WagonColumnComponent,
  TenantsDialogComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  AssetNamePipe,
  ShockIndexPipe,
  PredictionNamePipe,
  LocationPipe,
];
const DIRECTIVES = [TemplateRefId];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    ...NB_MODULES,
    ...OTHER_MODULES,
    NbSpinnerModule,
  ],
  exports: [
    CommonModule,
    ...PIPES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...NB_MODULES,
    ...OTHER_MODULES,
  ],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'nexxiot',
          },
          [DEFAULT_THEME, NEXXIOT_THEME],
        ).providers,
      ],
    };
  }
}
