import { AuthService } from './auth.service';

/**
 * The initializer should only be responsible for setting up the auth state.
 * Any redirect login should be implemented by components
 */
export function authInitializer(authService: AuthService) {
  return async function (): Promise<void> {
    await authService.restoreSession();
  };
}
