import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'ngx-unauthorized',
  template: `
    <div class="full-page">
      <h1>Tenant Unauthorized</h1>
      <a [href]="link">Back to Connect Platform</a>
    </div>
  `,
  styles: [
    `
      .full-page {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1004;
        background: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class UnauthorizedComponent {
  link = environment.connectUrl;
}
