import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { interval, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounce,
  map,
  startWith,
  switchMap,
} from 'rxjs/operators';
import {
  Geofence,
  GeofenceDataService,
} from '../../../../@core/services/geofence-data.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ngx-geofence-dialog',
  template: `
    <nb-card size="medium" class="m-0" style="width: 800px">
      <nb-card-header>Search Geofence</nb-card-header>
      <input
        type="text"
        class="form-control border"
        (keyup)="search$.next($any($event.target).value)"
        placeholder="Enter geofence name"
      />
      <nb-list>
        <nb-list-item
          *ngFor="let geofence of geofences$ | async"
          (click)="select(geofence)"
        >
          {{ geofence.name }}
        </nb-list-item>
      </nb-list>
    </nb-card>
  `,
})
export class GeofenceDialogComponent {
  search$ = new Subject<string>();

  geofences$: Observable<Geofence[]> = this.search$.pipe(
    untilDestroyed(this),
    debounce(() => interval(500)),
    startWith(''),
    switchMap((term) => this.geofenceService.search(term)),
    map((geofences) => geofences.items),
    catchError((err) => of([])),
  );

  constructor(
    private dialogRef: NbDialogRef<GeofenceDialogComponent>,
    private geofenceService: GeofenceDataService,
  ) {}

  select(geofence: Geofence) {
    this.dialogRef.close(geofence);
  }
}
