import { Injectable } from '@angular/core';
import {
  EntityState,
  EntityStore,
  QueryEntity,
  StoreConfig,
} from '@datorama/akita';
import { Asset, AssetDataService } from '../services/asset-data.service';
import produce from 'immer';

export type AssetsState = EntityState<Asset, string>;

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'assets', idKey: 'asset_id', producerFn: produce })
export class AssetsStore extends EntityStore<AssetsState> {
  constructor() {
    super();
  }
}

@Injectable({ providedIn: 'root' })
export class AssetsQuery extends QueryEntity<AssetsState> {
  constructor(store: AssetsStore) {
    super(store);
  }
}

@Injectable({ providedIn: 'root' })
export class AssetsService {
  constructor(
    private store: AssetsStore,
    private assetDataService: AssetDataService,
  ) {}

  async loadAssets(tenant: string): Promise<void> {
    try {
      this.store.setLoading(true);
      const assets = await this.assetDataService.findAll(tenant).toPromise();
      this.store.set(assets);
    } catch (error) {
      this.store.setError(error);
    } finally {
      this.store.setLoading(false);
    }
  }
}
