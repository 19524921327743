import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  QueryList,
  TemplateRef,
  ViewChildren,
} from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Shock,
  ShockTableOrderBy,
  ShockTableOrderDir,
  TablePageInfo,
} from '../../../@core/model/shockpit.interfaces';
import { shockId } from '../../../@core/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ShockTableItem,
  TableColumn,
  TemplateNames,
} from './shocks-table.interfaces';
import { ShocksTableConfigModalComponent } from './shocks-table-config-modal/shocks-table-config-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ShocksTableService } from './shocks-table.service';
import { DEFAULT_PAGE_INFO } from '../../../@core/services/shocks.service';
import { TemplateRefId } from '../../directives/template-ref-id.directive';
import * as wellknown from 'wellknown';

@Pipe({ name: 'location' })
export class LocationPipe implements PipeTransform {
  transform(value: string): string {
    console.log('calling location pipe');
    if (!value) return;
    const {
      coordinates: [lon, lat],
    } = wellknown.parse(value) as GeoJSON.Point;
    return `${lat.toFixed(4)}, ${lon.toFixed(4)}`;
  }
}

@UntilDestroy()
@Component({
  selector: 'ngx-shocks-table',
  templateUrl: './shocks-table.component.html',
  styleUrls: ['./shocks-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShocksTableComponent implements OnInit {
  private visited$ = new BehaviorSubject<string[]>([]);
  private bookmarked$ = new BehaviorSubject<string[]>([]);
  private shocks$ = new BehaviorSubject<Shock[]>([]);

  pageInfo: TablePageInfo = { ...DEFAULT_PAGE_INFO };

  rows$ = combineLatest([this.visited$, this.bookmarked$, this.shocks$]).pipe(
    untilDestroyed(this),
    map(([visited, bookmarked, shocks]) =>
      shocks.map((s) => ({
        ...s,
        visited: visited.includes(shockId(s)),
        bookmarked: bookmarked.includes(shockId(s)),
      })),
    ),
  );

  @Input() set rows(shocks: Shock[]) {
    this.isLoading = false;
    this.shocks$.next(shocks);
  }

  @Input() set visited(ids: string[]) {
    this.visited$.next(ids);
  }

  @Input() set bookmarks(ids: string[]) {
    this.bookmarked$.next(ids);
  }

  @Input() sortable = true;

  @Input() total;

  @Input() selectionType = undefined;

  @Input() footer = true;

  @Output() rowClick = new EventEmitter<Shock>();

  @Output() updateTablePagination = new EventEmitter<TablePageInfo>();

  ColumnMode = ColumnMode;

  columns$: Observable<TableColumn[]>;

  isLoading = false;

  getRowClass = (row: ShockTableItem): string => (row.visited ? 'visited' : '');

  _templates: { [key in TemplateNames]?: TemplateRef<unknown> } = {};

  @ViewChildren(TemplateRefId) set tpls(
    tpls: QueryList<TemplateRefId<TemplateNames>>,
  ) {
    for (const { templateId, templateRef } of tpls) {
      this._templates[templateId] = templateRef;
    }
  }

  constructor(
    private dialogService: NbDialogService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private shocksTableService: ShocksTableService,
  ) {}

  ngOnInit(): void {
    this.columns$ = this.shocksTableService.columns$.pipe(
      // merges the default sortability of a column with the table's configuration
      map((columns) =>
        columns.map((col) => ({
          ...col,
          sortable: col.sortable && this.sortable,
        })),
      ),
    );
  }

  addShockIdInQueryParams(shock: Shock, event: MouseEvent): void {
    event?.stopPropagation();
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        shockId: shockId(shock),
      },
    });
  }

  changePageCallback(pageInfo: {
    count?: number;
    pageSize?: number;
    limit?: number;
    offset?: number;
  }): void {
    this.pageInfo.page = pageInfo.offset + 1;
    this.isLoading = true;
    this.updateTablePagination.emit(this.pageInfo);
  }

  sortCallback(sortInfo: {
    sorts: { dir: string; prop: string }[];
    column: { name: string };
    prevValue: string;
    newValue: string;
  }): void {
    this.pageInfo.order_by = sortInfo.column.name
      .split(' ')
      .join('_')
      .toLowerCase() as ShockTableOrderBy;
    this.pageInfo.order_dir = sortInfo.sorts[0].dir as ShockTableOrderDir;
    this.isLoading = true;
    this.updateTablePagination.emit(this.pageInfo);
  }

  openConfigModal(): void {
    const dialogRef = this.dialogService.open(ShocksTableConfigModalComponent, {
      context: {
        allColumns: this.shocksTableService.allColumns(),
      },
    });
    dialogRef.onClose.subscribe((columns: TableColumn[]) => {
      this.shocksTableService.changeDisplayedColumns(columns);
    });
  }
}
